.product-carousel-container {
    padding: 20px;
    background: linear-gradient(270deg, #95e497, #44a79d, #179757);
    background-size: 600% 600%;
    animation: gradientAnimation 7s ease infinite;
    position: relative;
}

.product-carousel-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.product-carousel-item {
    padding: 10px;
}

.product-carousel-card {
    background: linear-gradient(270deg, #62a064, #426a66, #179757);
    background-size: 600% 600%;
    animation: gradientAnimation 7s ease infinite;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 320px;
    color: black;
}

.product-carousel-media {
    object-fit: contain;
    width: 100%;
    height: 150px;
}

@media (max-width: 768px) {
    .product-carousel-media {
        height: 120px;
    }
}

@media (max-width: 480px) {
    .product-carousel-media {
        height: 100px;
    }
}

.discount-chip {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #e74c3c;
    color: #ffffff;
    font-weight: bold;
}

.product-carousel-price-strikethrough {
    text-decoration: line-through;
    color: red;
}

.product-carousel-price {
    margin-top: 8px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #27ae60;
}

.product-carousel-button {
    margin-top: 16px;
    font-size: 0.875rem;
    padding: 8px 16px;
    background-color: #3498db;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.product-carousel-button:hover {
    background-color: #2980b9;
}

.slick-prev,
.slick-next {
    z-index: 1;
    background-color: #3498db;
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: #2980b9;
    color: black;
}

.slick-prev {
    left: -30px;
}

.slick-next {
    right: -30px;
}

.product-carousel-card-content {
    padding: 10px;
    color: black;
    flex-grow: 1;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
