.about-us-container {
    padding: 4rem;
    background: linear-gradient(45deg, #2E3033, #18787E, #3A434F, #0D0D0D);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    color: #F2CEAE;
}

.about-us-title {
    text-align: center;
    margin-bottom: 4rem;
    font-weight: bold;
    color: #F28A2E;
}

.about-us-description {
    text-align: center;
    margin-bottom: 4rem;
    animation: fadeIn 1.2s ease-in-out;
}

.mission-card, .team-card {
    background-color: rgba(46, 48, 51, 0.9);
    box-shadow: 3px 3px 10px #0D0D0D;
    color: #F2CEAE;
}

.mission-image-container, .team-image-container {
    height: 200px; /* Adjusted for better balance with the text boxes */
    overflow: hidden;
    border-radius: 2px;
    animation: zoomIn 1.5s ease-in-out;
    object-fit: cover; /* Ensures images are properly contained */
    width: 100%; /* Ensures the image covers the entire width of the box */
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
