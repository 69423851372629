.topbar-appbar {
    background-color: #107369;
    height: 50px;
  }
  
  .topbar-toolbar {
    justify-content: space-between;
    background-color: #107369;
    padding: 0 10px;
  }
  
  @media (min-width: 600px) {
    .topbar-toolbar {
      padding: 0 20px;
    }
  }
  
  .topbar-icon-button {
    color: inherit;
  }
  
  .topbar-typography {
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    flex-grow: 1;
  }
  
  @media (max-width: 600px) {
    .topbar-typography {
      font-size: 0.75rem;
    }
  }
  
  @media (min-width: 600px) {
    .topbar-typography {
      font-size: 1rem;
    }
  }
  