.filter-card {
    top: 140px;
    overflow-y: auto;
    background-color: #121b23; /* Fondo oscuro */
    border-radius: 8px;
    padding: 0;
    box-shadow: 3px 3px 10px rgba(193, 193, 193, 0.4); /* Sombra más pronunciada */
    z-index: 1;
    position: sticky;
    background: linear-gradient(270deg, #7d7d7d, #3f506a, #0f131c);
    background-size: 600% 600%;
    animation: gradientAnimation 7s ease infinite;
    width: 100%;

}

.filter-title {
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
    color: #ffffff; /* Color azul acorde con la estética */
}

.filter-divider {
    margin-bottom: 16px;
    background-color: #3498db; /* Color azul para el divisor */
}

.filter-section {
    margin-bottom: 16px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 16px;
}

.filter-header:hover{
  background-color: #121b2389;
}

.filter-subtitle {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;  /* Color azul */
}

.filter-option {
    font-size: 12px;
    color: #d2d2d2; /* Color de texto claro */
}

.no-options {
    font-size: 12px;
    color: #d2d2d2; /* Color de texto claro */
    text-align: center;
}

/* Estilos para resoluciones móviles */
@media (max-width: 768px) {
    .filter-card {
        top: 0;
        margin-top: 10px;
        box-shadow: none;
    }

    .filter-header {
        padding: 0 10px;
    }
}

.boton1 {
  background-color: #121b2389;
}
