.cover-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    overflow: hidden;
    gap: 20px;
  }
  
  @media (min-width: 768px) {
    .cover-image-container {
      flex-direction: row;
      height: 50vh; /* Ajusta la altura para acomodar la nueva disposición */
      gap: 20px;
    }
  }
  
  .cover-image-main {
    flex: 1.5;
    width: 100%;
    height: 40vw;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
  }
  
  @media (min-width: 768px) {
    .cover-image-main {
      height: 100%;
    }
  }
  
  .cover-image-main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s ease-in-out;
  }
  
  .cover-image-title {
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .cover-image-title {
      font-size: 2rem;
    }
  }
  
  .cover-image-small-container {
    flex: 1;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 imágenes por fila */
    grid-template-rows: repeat(2, 1fr); /* 2 filas */
    gap: 10px;
    height: 100%;
    margin-left: -25px;
  }
  
  .cover-image-small-box {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: filter 0.3s ease;
  }
  
  .cover-image-small-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cover-image-small-box:hover {
    filter: brightness(0.7);
  }
  
  .cover-image-small-title {
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1rem;
  }
  
  @media (min-width: 768px) {
    .cover-image-small-title {
      font-size: 1.5rem;
    }
  }
  