.middlebar-appbar {
    background-color: #0D0D0D;

    width: 100%;
  }
  
  .middlebar-toolbar {
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background-color: #0D0D0D;
  }
  
  
  .middlebar-logo {
    display: flex;
    align-items: center;
    height: 180px;
    max-width: 100%;
    text-decoration: none;
    margin-right: 20px;
  }

  
  .middlebar-logo img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  
  
  @media (min-width: 600px) {
    .middlebar-logo {
      height: 100%;
    }
  }
  
  .middlebar-searchbar {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
    width: 100%;
  }
  
  .middlebar-carticon {
    display: flex;
  }
  
  .middlebar-phone {
    display: none;
  }
  
  @media (min-width: 768px) {
    .middlebar-phone {
      display: flex;
      align-items: center;
      gap: 1;
      flex-shrink: 0;
    }
  }
  
  .middlebar-phone-number {
    color: #ffffff;
    font-weight: bold;
    white-space: nowrap;
  }
  
  .middlebar-phone-tracking {
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
  }
  