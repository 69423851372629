/* BottomBar.css */
#bottom-bar {
    z-index: 1000;
    transition: height 0.3s ease, top 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

#bottom-bar.fixed {
    position: fixed;
    background-color: #107369;
    height: 80px;
    top: 0;
    bottom: auto;
    padding-top: 10px;
}

#bottom-bar.static {
    position: static;
    background-color: #0d685f;
    height: 50px;
    top: auto;
    bottom: 0;
}

.bottom-bar-toolbar {
    justify-content: space-between;
    align-items: center;
}

.bottom-bar-logo {
    height: 40px;
    margin-left: 20px;
}

.bottom-bar-menu {
    display: none;
    gap: 2.5rem;
    justify-content: center;
    flex-grow: 1;
    padding-bottom: 10px;
}

@media (min-width: 768px) {
    .bottom-bar-menu {
        display: flex;
    }
}

.bottom-bar-menu-item {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.bottom-bar-menu-item:hover {
    background-color: #18777ea3;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.bottom-bar-menu-item:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    background-color: #ffffff;
}

.bottom-bar-menu-item.tienda-button {
    background-color: #0b1a295b;
    color: #ffffff;
    padding: 10px 25px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.bottom-bar-menu-item.tienda-button:hover {
    background-color: #18787E;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.bottom-bar-submenu {
    position: relative;
}

.bottom-bar-submenu-content {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    pointer-events: none;
    width: 200px;
}

.bottom-bar-submenu-content.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.bottom-bar-submenu-item {
    display: block;
    padding: 8px 16px;
    color: #3498db;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.bottom-bar-submenu-item:hover {
    background-color: #f1f1f1;
}

.bottom-bar-icon-button {
    color: #ffffff;
}

.drawer-content {
    width: 250px;
}

.drawer-list-item {
    text-align: left;
}
