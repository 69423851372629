.new-arrivals-carousel-container {
    padding: 20px;
    background-color: #66c587;
    background-size: 600% 600%;
    position: relative;
}

.new-arrivals-carousel-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.new-arrivals-carousel-item {
    background-color: #2b3163;
    background-size: 600% 600%;
    animation: gradientAnimation 7s ease infinite;
    color: rgb(210, 210, 210);
    padding: 0px;
}

.new-arrivals-carousel-card {
    background: linear-gradient(270deg, #18787E, #0c383b, #11555a);
    background-size: 600% 600%;
    animation: gradientAnimation 7s ease infinite;
    overflow: hidden;
    position: relative;
    padding: 15px;
    color: rgb(210, 210, 210);
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 3px;
}

.new-arrivals-carousel-media {
    object-fit: contain;
    width: 100%;
    height: 150px;
}

@media (max-width: 768px) {
    .new-arrivals-carousel-media {
        height: 120px;
    }
}

@media (max-width: 480px) {
    .new-arrivals-carousel-media {
        height: 100px;
    }
}

.new-arrivals-carousel-card-content {
    background: linear-gradient(270deg, #18787E, #0c383b, #11555a);
    background-size: 600% 600%;
    animation: gradientAnimation 7s ease infinite;
    color: rgb(210, 210, 210);
    flex-grow: 1;
}

.new-arrivals-carousel-price {
    margin-top: 8px;
    color: rgb(210, 210, 210);
}

.new-arrivals-carousel-button {
    margin-top: 16px;
    font-size: 0.875rem;
    padding: 8px 16px;
}

.slick-prev,
.slick-next {
    z-index: 1;
    background-color: #3498db;
    border-radius: 4px;
    padding: 8px;
    color: #ffffff;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: #83b9ddb1;
    color: black;
}

.slick-prev {
    margin-left: 10px;
}

.slick-next {
    margin-right: 10px;
}

.new-arrival-chip {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #27ae60;
    color: rgb(210, 210, 210);
    font-weight: bold;
}
