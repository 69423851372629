.footer-container {
    background-color: #0D0D0D;
    color: #ffffff;
    padding: 20px;
    max-width: 100vw;
    overflow: hidden;
    z-index: 5;
}

.footer-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer-logo {
    height: 60px;
    margin-bottom: 10px;
}

.footer-title {
    font-weight: bold;
    color: #F2B90C;
    text-align: center;
    margin-bottom: 10px;
}

.footer-description {
    color: #F2CEAE;
    margin-top: 10px;
}

.footer-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.footer-icon {
    margin-right: 10px;
    color: #F2CEAE;
}

.footer-text {
    color: #F2CEAE;
}

.footer-social {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.footer-link {
    color: #F28A2E;
    text-decoration: none;
}

.footer-link:hover {
    color: #F2B90C;
}

.footer-categories, .footer-pages {
    text-align: center;
    margin-bottom: 20px;
}
