.contacto-container {
    background: linear-gradient(-45deg, #18787E, #F2B90C, #F28A2E, #F2CEAE);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
}

.contacto-paper {
    padding: 4rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1.5rem;
    max-width: 1200px;
    margin: auto;
}

.contacto-title {
    text-align: center;
    margin-bottom: 4rem;
    font-weight: bold;
    color: #F28A2E;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
}

.contacto-description {
    color: #0D0D0D;
}

.contacto-textfield {
    margin-bottom: 2rem;
}

.contacto-textfield .MuiOutlinedInput-root fieldset {
    border-color: #F28A2E;
}

.contacto-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: #18787E;
}

.contacto-button {
    background-color: #F28A2E;
    padding: 1rem 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
}

.contacto-button:hover {
    background-color: #18787E;
}

.contacto-map-container {
    width: 100%;
    height: 300px;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contacto-social-icons {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 2rem;
}

.contacto-social-icons .MuiIconButton-root {
    color: #F28A2E;
}

.contacto-social-icons .MuiIconButton-root:hover {
    color: #18787E;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
