@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .tienda-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 0px;
    background: linear-gradient(270deg, #e0e0e0, #b0c4de, #d3d3d3);
    background-size: 600% 600%;
    animation: gradientAnimation 3s ease infinite;
  }
  
  @media (min-width: 768px) {
    .tienda-container {
      flex-direction: row;
    }
  }
  
  .tienda-sidebar {
    width: 100%;
    padding: 0;
    z-index: 900;
    margin-top: 20px;
  }
  
  @media (min-width: 768px) {
    .tienda-sidebar {
      width: 25%;
      position: sticky;
      top: 100px;
    }
  }
  
  .tienda-content {
    width: 100%;
    padding: 16px 32px;
    justify-items: flex-end;
  }
  
  @media (min-width: 768px) {
    .tienda-content {
      width: 75%;
    }
  }
  
  .tienda-card {
    max-width: 100%;
    margin: auto;
    background-color: #121b23; /* Fondo celeste muy claro */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    overflow: hidden;
  }
  
  .tienda-card-sm {
    max-width: 345px;
  }
  
  .tienda-media {
    object-fit: contain;
    width: 100%;
    height: 200px;
  }
  
  @media (max-width: 768px) {
    .tienda-media {
      height: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .tienda-media {
      height: 120px;
      width: 100%;
    }
  }
  
  .tienda-price-strikethrough {
    text-decoration: line-through;
    color: red;
  }
  
  .tienda-price {
    margin-top: 8px;
  }
  
  /* Ajustes de estilo para texto y botones en pantallas pequeñas */
  .tienda-card .MuiTypography-h6 {
    font-size: 1rem;
  }
  
  .tienda-card .MuiTypography-body2 {
    font-size: 0.875rem;
  }
  
  .tienda-button {
    margin-top: 16px;
    font-size: 0.875rem;
    padding: 8px 16px;
  }
  
  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    .tienda-card {
      max-width: 90%;
      margin-left: 50px;
    }
    .tienda-content {
      padding: 8px;
      margin-left: -40px;
    }
  }
  
  @media (max-width: 480px) {
    .tienda-card {
      max-width: 70%;
      max-width: 90%;
      margin-left: 50px;
    }
    .tienda-content {
      padding: 4px 8px;
      max-width: 90%;
      margin-left: 50px;
    }

    .tienda-card {
      margin-left: -40px;
    }
    .tienda-card .MuiTypography-h6 {
      font-size: 0.875rem;
    }
    .tienda-card .MuiTypography-body2 {
      font-size: 0.75rem;
    }
    .tienda-button {
      font-size: 0.75rem;
      padding: 6px 12px;
    }
  }
  
  .cards {
    background-color: rgb(197, 212, 221);
  }
  